import React from 'react'
import styles from './customers.module.css'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);

const Customers = () => {
  const data = [
    {
      url: "https://www.instagram.com/cubecollabs/?igsh=NzN6aHcxcjd0YzMw&utm_source=qr#",
      image: require('../../assets/images/Thumbnails_video_S1-01.png')
    },
    {
      url: "https://www.instagram.com/cubecollabs/?igsh=NzN6aHcxcjd0YzMw&utm_source=qr#",
      image: require('../../assets/images/Thumbnails_video_S1-02.png')
    },
    {
      url: "https://www.instagram.com/cubecollabs/?igsh=NzN6aHcxcjd0YzMw&utm_source=qr#",
      image: require('../../assets/images/Thumbnails_video_S1-03.png')
    },

   
    

  ]
  return (
    <div className={styles.container}>

      <div className={styles.wrapper}>
        <div className={styles.slider}>
          <div className={styles.slidetrack}>

            {
              data.map((item, index) => (
                <a href={item?.url} target='_blank'>
                  <div className={styles.box1}>
                    <div className={styles.customer} key={index}>
                      <img src={item?.image} className={styles.mainImg} style={{ width: '100%',height:'auto' }} />
                    </div>
                  </div>
                </a>
              ))
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default Customers
