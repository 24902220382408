import React from 'react'
import styles from "./vision.module.css"
import { Coma } from '../../components/Svg'
const Vision = ({vr}) => {
   return (
      <div className={styles.container}>
         <div className={styles.section}>
            <div className={styles.box1}>
               <h2 className={styles.heading}>What do we offer</h2>
               <div className={styles.vision_text}>
                  <div className={styles.vision_icon}>
                     <Coma />
                  </div>
                  <p className={styles.head}>Our current offering is a series of content named “Believe in Brands” that provides curious enterprising minds with a palette of perspective from Industry through the means of engaging videos.</p>

               </div>
               <hr className={styles.line}></hr>
               <h2 className={styles.heading}>What are we aspiring for?
               </h2>
               <div className={styles.vision_text}>
                  <div className={styles.vision_icon}>
                     <Coma />
                  </div>
                  <p className={styles.head}  ref={vr}>To help professionals connect, communicate and collaborate with utilitarian SaaS tools. </p>

               </div>

               <hr className={styles.line}></hr>
               <h2 className={styles.heading} >The cube slide!</h2>
               <div className={styles.vision_text} >
                  <div className={styles.vision_icon}>
                     <Coma />
                  </div>
                  <p className={styles.head}>The Cube Slide symbolizes a journey from the challenges of traditional professional hurdles to a state of effortless productivity and seamless workflow. By addressing key obstacles, the Cube Slide transforms the workplace experience, enabling professionals to “slide” smoothly into a more efficient, collaborative, and connected environment</p>

               </div>

            </div>
            <div className={styles.box2}>
               <img src="./assets/images/image39.png" className={styles.mainImg} alt="photo" onContextMenu={(e) => e.preventDefault()} />
            </div>
         </div>
      </div>
   )
}

export default Vision
