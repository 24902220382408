import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import Community from "../../smallcomponents/community/Community";
import AboutCube from "../../smallcomponents/aboutCube/AboutCube";
import Vision from "../../smallcomponents/vision/Vision";
import ConnectUs from "../../smallcomponents/connectUs/ConnectUs";
import GetInTouch from "../../smallcomponents/getTouch/GetInTouch";
import styles from "./home.module.css";
import Header from "../header/Header";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const imageContainerRef = useRef(null);
  const headerRef = useRef(null);

  const title1Ref = useRef(null);
  const title2Ref = useRef(null);
  const title3Ref = useRef(null);
  const title21Ref = useRef(null);
  const title22Ref = useRef(null);
  const title23Ref = useRef(null);
  const [active, setActive] = useState(3);
  const [activeAll, setActiveAll] = useState(false)
  const aboutCubeRef = useRef(null);
  const comunityRef = useRef(null);
  const visionRef = useRef(null)
  const joinInstagramRef = useRef(null);
  const watchYouTubeRef = useRef(null);
  const footerref = useRef(null);
  const [hideNotification, setHideNotification] = useState(false)

  useLayoutEffect(() => {
    const mm = gsap.matchMedia();

    mm.add("(min-width: 992px)", () => {
      gsap.to(imageContainerRef.current, {
        yPercent: -15,
        ease: "none",
        scrollTrigger: {
          trigger: imageContainerRef.current, // The image section
          start: "1%", // Start when the top of the image hits the top of the viewport
          end: "6% 0%", // Pin until the image is fully revealed (100% of the container height)
          scrub: 2, // Increase scrub to slow down animation
          refreshPriority: 1,
        },
      });

      // Scale animation for the second image (bottom) to increase in size
      gsap.to(imageContainerRef.current.querySelector("img:nth-of-type(2)"), {
        scale: 0.4, // Scale up the image size
        ease: "none",
        scrollTrigger: {
          trigger: imageContainerRef.current,
          start: "1%",
          end: "6% 0%",
          scrub: 2, // Increase scrub to slow down animation
          refreshPriority: 1,
        },
      });

      gsap.to(imageContainerRef.current.querySelector("img:nth-of-type(2)"), {
        top: "26vh", // Scale up the image size
        ease: "none",
        scrollTrigger: {
          trigger: imageContainerRef.current,
          start: "1%",
          end: "6% 0%",
          scrub: 2, // Increase scrub to slow down animation
          refreshPriority: 1,
        },
      });
      // Scale animation for the first image (top) to reduce in size
      gsap.to(imageContainerRef.current.querySelector("img:nth-of-type(3)"), {
        scale: 1.9, // Scale down the image size
        ease: "none",
        scrollTrigger: {
          trigger: imageContainerRef.current,
          start: "1%",
          end: "6% 0%",
          scrub: 2, // Increase scrub to slow down animation
          refreshPriority: 1,
        },
      });
      gsap.to(imageContainerRef.current.querySelector("img:nth-of-type(3)"), {
        bottom: "14vh", // Scale down the image size
        ease: "none",
        scrollTrigger: {
          trigger: imageContainerRef.current,
          start: "1%",
          end: "6% 0%",
          scrub: 2, // Increase scrub to slow down animation
          refreshPriority: 1,
        },
      });
    });

  }, []);

  useLayoutEffect(() => {
    const mm = gsap.matchMedia();
    mm.add("(min-width: 992px)", () => {
      setActiveAll(false)
      ScrollTrigger.create({
        trigger: imageContainerRef.current,
        start: "top 50%",
        end: "bottom 50%",
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          if (progress <= 0.43) {
            title1Ref.current.style.color = "#969292";
            title2Ref.current.style.color = "#cecaca";
            title3Ref.current.style.color = "white";

            title21Ref.current.style.color = "#969292";
            title22Ref.current.style.color = "#cecaca";
            title23Ref.current.style.color = "white";

            setActive(3);
          } else if (progress > 0.4 && progress <= 0.5) {
            title1Ref.current.style.color = "#969292";
            title2Ref.current.style.color = "white";
            title3Ref.current.style.color = "#969292";

            title21Ref.current.style.color = "#969292";
            title22Ref.current.style.color = "white";
            title23Ref.current.style.color = "#969292";
            setActive(2);
          } else {
            title1Ref.current.style.color = "white";
            title2Ref.current.style.color = "#cecaca";
            title3Ref.current.style.color = "#969292";

            title21Ref.current.style.color = "white";
            title22Ref.current.style.color = "#cecaca";
            title23Ref.current.style.color = "#969292";
            setActive(1);
          }
        },
      });
    });
    mm.add("(max-width: 992px)", () => {
      setActiveAll(true)
      title1Ref.current.style.color = "white";
      title2Ref.current.style.color = "white";
      title3Ref.current.style.color = "white";
      title21Ref.current.style.color = "white";
      title22Ref.current.style.color = "white";
      title23Ref.current.style.color = "white";
    })

    return () => mm.revert();
  }, []);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // setTimeout(() => {
  //   setHideNotification(true)
  // }, 9000)


  return (
    <>

      <header ref={headerRef} className={styles.header}>
        <Header headerRef={headerRef} scrollToSection={scrollToSection} aboutCubeRef={aboutCubeRef} joinInstagramRef={joinInstagramRef} watchYouTubeRef={watchYouTubeRef} footerref={footerref} />
      </header>
      {/* <div style={{ display:hideNotification ? 'none' : 'flex'  }}> */}
      <div className={styles.notification} >
        <marquee behavior="" direction=""> <span >For better experience switch to desktop</span> </marquee>
      </div>
      {/* </div> */}

      <div className={styles.imageWrapper}>
        <div className={styles.topGradient}></div>
        <div style={{ position: 'absolute', width: '100%', zIndex: 1, display: 'flex', justifyContent: 'space-between' }}>

          <div className={styles.heading}>
            <div style={{ display: 'flex', alignItems: 'center' }}>

              <img src={require('../../assets/images/rocket.png')} alt="" srcset="" className={styles.rocket} style={{ display: (active == 1 || activeAll) ? 'flex' : 'none' }} />

              <h1
                className={styles.title1}
                style={{ cursor: (active == 1 || activeAll) ? "pointer" : "auto" }}
                ref={title1Ref}
                onClick={() =>
                  (active == 1 || activeAll) &&
                  scrollToSection(aboutCubeRef)
                }
              >
                What is Cube Collabs?
              </h1>

            </div>
            <div className={styles.text1}>
              <div className={styles.rocketContainer}>
                <img src={require('../../assets/images/rocket.png')} alt="" srcset="" className={styles.rocket} style={{ display: (active == 2 || activeAll) ? 'flex' : 'none' }} />
              </div>
              <h1
                className={styles.title2}
                ref={title2Ref}
                style={{ cursor: (active == 2 || activeAll) ? "pointer" : "auto" }}
                onClick={() =>
                  (active == 2 || activeAll) &&
                  scrollToSection(joinInstagramRef)
                }
              >
                Join us in Instagram
              </h1>

            </div>
            <div className={styles.text2}>
              <div className={styles.rocketContainer}>
                <img src={require('../../assets/images/rocket.png')} alt="" srcset="" className={styles.rocket} style={{ display: (active == 3 || activeAll) ? 'flex' : 'none' }} />
              </div>

              <h1
                className={styles.title3}
                ref={title3Ref}
                style={{ cursor: (active == 3 || activeAll) ? "pointer" : "auto" }}
                onClick={() =>
                  (active == 3 || activeAll) &&
                  scrollToSection(joinInstagramRef)
                }
              >
                Watch us on YouTube
              </h1>

            </div>
          </div>

          <div className={styles.heading2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1
                className={styles.titleh1}
                style={{ cursor: (active == 1 || activeAll) ? "pointer" : "auto" }}
                ref={title21Ref}
              onClick={() =>
                (active == 1 || activeAll) &&
                scrollToSection(joinInstagramRef)
              }
              >
                Believe in Brands
              </h1>
              <div className={styles.rocketContainer}>
                <img src={require('../../assets/images/rocket.png')} alt="" srcset="" className={styles.rocket} style={{ display: (active == 1 || activeAll) ? 'flex' : 'none' }} />
              </div>
            </div>
            <div className={styles.texth1}>

              <h1
                className={styles.titleh2}
                ref={title22Ref}
                style={{ cursor: (active == 2 || activeAll) ? "pointer" : "auto" }}
              onClick={() =>
                (active == 2 || activeAll) &&
                scrollToSection(comunityRef)
              }
              >
                What is next?⁠
              </h1>
              <div className={styles.rocketContainer}>
                <img src={require('../../assets/images/rocket.png')} alt="" srcset="" className={styles.rocket} style={{ display: (active == 2 || activeAll) ? 'flex' : 'none' }} />
              </div>
            </div>

            <div className={styles.texth2}>
              <h1
                className={styles.titleh3}
                ref={title23Ref}
                style={{ cursor: (active == 3 || activeAll) ? "pointer" : "auto" }}
              onClick={() =>
                (active == 3 || activeAll) &&
                scrollToSection(visionRef)
              }
              >
                The Cube slide!
              </h1>
              <div className={styles.rocketContainer}>
                <img src={require('../../assets/images/rocket.png')} alt="" srcset="" className={styles.rocket} style={{ display: (active == 3 || activeAll) ? 'flex' : 'none' }} />
              </div>
            </div>
          </div>
        </div>
        <div>

          <div ref={imageContainerRef} className={styles.imageContainer}>

            <img
              src={require("../../assets/images/animationfinal.png")}
              alt="Space Animation"
              className={styles.backgroundimage}
              style={{
                transition: "scale 1s",
                pointerEvents: "none"
              }}
              onContextMenu={(e) => e.preventDefault()}
            />
            <img
              src={require("../../assets/images/animationimage2.png")}
              alt=""
              className={styles.firstimage}
              style={{
                position: "absolute",
                transition: "scale 0.1s",
                pointerEvents: "none"
              }}
              onContextMenu={(e) => e.preventDefault()}
            />
            <img
              src={require("../../assets/images/animationimage1.png")}
              alt=""
              className={styles.secondimage}
              style={{
                position: "absolute",
                transition: "scale 0.1s",
                pointerEvents: "none"
              }}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
          <div className={styles.bottomGradient}></div>

          <div className={styles.mobileimage}>
            <img src={require('../../assets/images/backroundimage.png')} alt="" srcset="" className={styles.mobileimagesource} />
          </div>
        </div>


      </div>

      {/* Rest of the content */}

      <div ref={comunityRef} style={{paddingTop:1}}>
        <Community />
      </div>
      <div ref={aboutCubeRef}>
        <div>
          <AboutCube />
        </div>
      </div>
      <div >

      <Vision  vr={visionRef}/>
      </div>
      <div ref={joinInstagramRef}>
        <ConnectUs />
      </div>
      <div id="from-cube-collab">
        <div>
          <GetInTouch />
        </div>
      </div>
      <div ref={footerref}>

      </div>
    </>
  );
};

export default Home;
